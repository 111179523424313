import { Box, Button, Container, Grid, SxProps, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import EditionsImage from "../../../public/images/front-page/editions.png";
import Image from "next/image";
import Link from "next/link";
import React from "react";

type EditionsProps = { sectionContainer?: SxProps };

export const Editions: React.FC<EditionsProps> = ({ sectionContainer = {} }) => {
  const bgColor = "#DBF2FD";

  return (
    <Box
      component="section"
      id="editions"
      sx={{
        backgroundColor: bgColor,
        boxShadow: "sectionVertical",
        position: "relative",
        zIndex: 5,
        ...sectionContainer,
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          pt: { xs: 8, sm: 8, md: 10, lg: 14, xl: 16 },
          pb: { xs: 6, sm: 6, md: 8, lg: 10, xl: 12 },
        }}
      >
        <Grid
          alignItems={{ xs: "center" }}
          container
          direction={{ xs: "column-reverse", sm: "row" }}
          spacing={{ xs: 2, sm: 6, lg: 12 }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h2" sx={{ hyphens: "none", pb: 6 }}>
              Vous pouvez maintenant organiser vos soirées dîner‑enquête en ligne
            </Typography>
            <Typography variant="body1" paragraph>
              Avec Culinario Mortale NUMÉRIQUE, vous pouvez aussi jouer en ligne. Tous les joueurs
              peuvent recevoir leur livret de jeu directement sur leur smartphone, leur tablette ou leur
              ordinateur.
            </Typography>

            <Typography variant="body1" paragraph>
              Il n’est plus nécessaire d’envoyer les livrets par la poste. Vous pouvez par exemple
              organiser le jeu par vidéoconférence (Skype, Zoom, etc.).
            </Typography>

            <Button
              endIcon={<ChevronRightIcon />}
              href="/diner-enquete-en-ligne"
              LinkComponent={Link}
              sx={{ mt: 3, width: { xs: "100%", sm: "unset" } }}
              variant="contained"
            >
              En savoir plus
            </Button>
          </Grid>
          <Grid item xs={12} sm={0} md={6} lg={6}>
            <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
              <Image
                alt="Un livret de jeu imprimé et la version en ligne accessible"
                title="Qu'il s'agisse d'un dîner mystère en ligne ou d'une version imprimée, Culinario Mortale est toujours un bon choix."
                sizes={`
                    (max-width: 767px) 80vw,
                    (min-width: 768px) 35vw,
                  `}
                src={EditionsImage}
                style={{ width: "100%", height: "auto" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
