import { Box, Container, Grid, Typography, useTheme } from "@mui/material";
import { cmsApiClient, CmsApiAcl } from "@cm-websites/common/dist/cms-api";
import { Editions, HeroHeader, Testimonials } from "../front-page";
import { Explainer, ProductSelection, YoutubeEmbed } from "@cm-websites/common";
import { Footer } from "../footer";
import { HEADER_ITEMS } from "../header";
import { MOCK_QUOTES } from "../front-page/testimonials/mock";
import { NextSeo } from "next-seo";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import i18nConfig from "../../next-i18next.config";
import Image from "next/legacy/image";
import MoodGeschenk from "../../public/images/front-page/krimidinner-verschenken.jpg";
import MoodTable from "../../public/images/front-page/mood-table.jpg";
import React from "react";
import type { CmsApiModel } from "@cm-websites/common/dist/cms-api";
import type { GetStaticProps } from "next";
import type { Product } from "@cm-websites/common";
import YoutubePoster from "../../public/images/front-page/youtube-poster.jpg";
import YoutubePosterSection from "../../public/images/front-page/youtube-poster-section.jpg";

type HomeProps = {
  featuredProduct: Product;
  products: Product[];
};

export const HomePage: React.FC<HomeProps> = ({ featuredProduct, products }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <NextSeo
        title="Culinario Mortale® – Le jeu des dîners mortels"
        description="Culinario Mortale est un diner enquête clé en main. Les joueurs devront mener l'enquête pour trouver le coupable qui se trouve parmi eux."
        openGraph={{
          type: "website",
          url: "https://www.culinario-mortale.fr",
          title: "Culinario Mortale® – Le jeu des dîners mortels",
          description:
            "Culinario Mortale est un diner enquête clé en main. Les joueurs devront mener l'enquête pour trouver le coupable qui se trouve parmi eux.",
        }}
      />
      <HeroHeader navItems={HEADER_ITEMS} />
      <ProductSelection
        showPrice={false}
        products={products}
        featuredProductId="CM09FR"
        recommendedProductIds={["CM07FR", "CM03FR"]}
      />
      <Explainer>
        <Box sx={{ mb: { xs: 4, sm: 8 } }}>
          <YoutubeEmbed
            aspectRatio="16 / 9"
            id="7fNrXJAyLbU"
            posterImage={YoutubePoster}
            title="Culinario Mortale® – Le jeu des dîners mortels"
          />
        </Box>
      </Explainer>
      <Editions />
      <Box component="section" sx={{ position: "relative" }}>
        <Container
          maxWidth="xl"
          sx={{ pt: { xs: 8, sm: 10, md: 16 }, pb: { xs: 6, sm: 8, md: 10 }, overflowX: "hidden" }}
        >
          <Grid alignItems="center" container spacing={{ xs: 6, sm: 10 }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                "> span": { borderRadius: theme.shape.borderRadius, overflow: "hidden" },
                pb: 0,
              }}
            >
              <YoutubeEmbed
                aspectRatio="16 / 9"
                id="Lxqq6AK5uHE"
                posterImage={YoutubePosterSection}
                title="Culinario Mortale® – Le jeu des dîners mortels"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ pb: { xs: 3, md: 5 }, hyphens: "none" }}>
                Organisez votre dîner‑enquête
              </Typography>
              <Typography variant="body1" paragraph>
                Invitez vos amis à dîner et glissez-vous tous dans la peau des principaux suspects d’une
                mystérieuse affaire criminelle.
              </Typography>

              <Typography variant="body1" paragraph>
                Le but du jeu est de découvrir le ou la coupable se dissimulant parmi vous. Pour cela,
                les joueurs et joueuses vont passer ensemble en revue les faits en faisant appel à leurs
                souvenirs. Mais attention : chaque personnage (dont le vôtre) a des secrets qu’il
                préférerait ne pas révéler.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box component="section" sx={{ position: "relative" }}>
        <Container
          maxWidth="xl"
          sx={{ pt: { xs: 0, sm: 0, md: 10 }, pb: { xs: 0, sm: 10 }, overflowX: "hidden" }}
        >
          <Grid
            alignItems="center"
            container
            direction={{ xs: "column-reverse", md: "row" }}
            spacing={{ xs: 6, sm: 10 }}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ pb: { xs: 3, md: 5 }, hyphens: "none" }}>
                Le lieu du crime : votre table
              </Typography>
              <Typography variant="body1" paragraph>
                Passez une soirée inoubliable, pleine de suspense, d’intrigues et de rebondissements avec
                nos dîners mortels. Qui plus est, cela se passe directement à votre table. Le principe
                est simple et les règles du jeu tiennent sur une page : même les débutants s’y
                retrouvent.
              </Typography>
              <Typography variant="body1" paragraph>
                Grâce au code-support inclus dans votre jeu, vous pouvez télécharger facilement les
                descriptions des personnages afin de les envoyer à vos invités en amont de la soirée.
                Chaque joueur ou joueuse peut ainsi découvrir tranquillement son rôle avant la partie et
                réfléchir à son déguisement.
              </Typography>

              <Typography variant="body1" paragraph>
                Le principe en est simple, mais seuls de vrais détectives peuvent élucider ces meurtres.
                Nos dîners-enquêtes durent plusieurs heures et nous avons pour cela trouvé des idées de
                menus pour votre soirée. De quoi régaler vos invités !
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                "> span": { borderRadius: theme.shape.borderRadius, overflow: "hidden" },
                pb: 0,
              }}
            >
              <Image alt="" placeholder="blur" title="" src={MoodTable} />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box component="section" sx={{ position: "relative" }}>
        <Container
          maxWidth="xl"
          sx={{ pt: { xs: 0, sm: 0, md: 10 }, pb: { xs: 0, sm: 10 }, overflowX: "hidden" }}
        >
          <Grid
            alignItems="center"
            container
            direction={{ xs: "column-reverse", md: "row" }}
            spacing={{ xs: 6, sm: 10 }}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                "> span": { borderRadius: theme.shape.borderRadius, overflow: "hidden" },
                pb: 0,
              }}
            >
              <Image alt="" placeholder="blur" title="" src={MoodGeschenk} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h2" sx={{ pb: { xs: 3, md: 5 }, hyphens: "none" }}>
                Le cadeau parfait pour les fans d’enquêtes policières
              </Typography>
              <Typography variant="body1" paragraph>
                Les jeux Culinario Mortale font d’excellents cadeaux. Offrez un dîner-enquête à vos amis,
                votre famille ou vos collègues. Que ce soit à l’occasion d’un anniversaire, pour le
                Nouvel An ou votre prochain barbecue, nos jeux contiennent tout le nécessaire pour passer
                une soirée inoubliable.
              </Typography>
              <Typography variant="body1" paragraph>
                Le temps presse et votre dîner a lieu demain ? Trouvez le revendeur le plus proche où
                acheter nos jeux.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Testimonials quotes={MOCK_QUOTES} sectionContainer={{ mt: 6, mb: 0 }} />

      <Footer />
    </React.Fragment>
  );
};

export default HomePage;

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const fields = [
    "*.*",
    "printVersionImages.*",
    "printVersionImages.directus_files_id.mask",
    "printVersionImages.directus_files_id.id",
    "printVersionImages.directus_files_id.description",
    "onlineVersionImages.*",
    "onlineVersionImages.directus_files_id.mask",
    "onlineVersionImages.directus_files_id.id",
    "onlineVersionImages.directus_files_id.description",
  ];

  const { data } = await cmsApiClient.get<{
    data: CmsApiModel.components["schemas"]["ItemsCulinarioMortale"][];
  }>(
    `/items/Culinario_Mortale?filter={"language": "FR", "status": "published"}&fields=${fields.join(
      ","
    )}`
  );

  const products = (await Promise.all(data.data.map((p) => CmsApiAcl.product(p, "print")))).sort(
    (a, b) => {
      const nameA = a.id.toUpperCase(); // ignore upper and lowercase
      const nameB = b.id.toUpperCase(); // ignore upper and lowercase

      // sort in an descending order
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }

      // names must be equal
      return 0;
    }
  );
  const featuredProduct = products[0];

  return {
    props: {
      ...(locale
        ? await serverSideTranslations(
            locale,
            ["c-common", "c-product", "c-user-consent-management"],
            i18nConfig
          )
        : {}),
      products,
      featuredProduct,
    },
  };
};
